<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col>
        <template>
          <v-card elevation="0">
            <v-data-table
              :headers="TransitModelHeader"
              :items="allchalanArray"
              :search="searchchalan"
              :loading="loading"
              loading-text="Please wait..."
              item-key="id"
              v-model="selected"
              :single-select="singleSelect"
              show-select
              class="elevation-0"
              style="background: #f5f5f5"
              return-object
            >
              <template v-slot:top>
                <v-toolbar
                  elevation="2"
                  style="background: #f5f5f5; font-size: 26px"
                >
                  <v-toolbar-title
                    style="margin-left: -14px"
                    class="mt-2 mb-2 ml-2"
                  >
                    <v-select
                      v-model="trnasferob"
                      :items="AllWarehouseList"
                      item-text="name"
                      @change="TransferSelectInfo(trnasferob)"
                      return-object
                    ></v-select>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pa-5">
                    <v-text-field
                      v-model="searchchalan"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div class="pa-5">
                    <v-btn
                      color="primary"
                      @click="savetransit(item)"
                      :disabled="!selected.length"
                    >
                      Create Request
                    </v-btn>
                  </div>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>
              <template
                v-slot:item.actionsqty="{ item }"
                style="max-width: 30px"
              >
                <v-text-field
                  v-model="item.requested_qty"
                  outlined
                  dense
                  class="mt-5"
                  style="max-width: 100px"
                ></v-text-field>
              </template>

              <template v-slot:item.admin_status="{ item }">
                <v-chip :color="getColor(item.admin_status)" dark>
                  {{ item.admin_status }}
                </v-chip>
              </template>
              <template
                v-slot:item.actionsqty="{ item }"
                style="max-width: 30px"
              >
                <v-text-field
                  v-model="item.requested_qty"
                  outlined
                  dense
                  class="mt-5"
                  style="max-width: 100px"
                ></v-text-field>
              </template>
            </v-data-table>

            <v-dialog v-model="Transfersavedialog" max-width="1200px">
              <v-card>
                <v-data-table
                  :headers="TransitModelHeader"
                  :items="selected"
                  item-key="id"
                  v-model="selected"
                  :single-select="singleSelect"
                  show-select
                  class="elevation-1"
                  style="background: #f5f5f5"
                  return-object
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:top>
                    <v-toolbar
                      elevation="2"
                      style="background: #f5f5f5; font-size: 26px"
                    >
                      <p class="mb-0">Confirm Product</p>
                      <v-spacer></v-spacer>

                      <v-divider class="mx-4" inset vertical></v-divider>
                    </v-toolbar>
                  </template>

                    <template
                v-slot:item.actionsqty="{ item }"
                style="max-width: 30px"
              >
                <v-text-field
                  v-model="item.requested_qty"
                  outlined
                  dense
                  class="mt-5"
                  style="max-width: 100px"
                ></v-text-field>
              </template>
                </v-data-table>
                <v-card-actions class="py-5">
                  <v-spacer></v-spacer>

                  <v-btn elevation="0" color="red" @click="CancelInvoice()">
                    Cancel
                  </v-btn>
                  <v-btn
                    @click="SaveApproveInvoice(item)"
                    elevation="0"
                    color="success"
                  >
                    Confirm Request
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    crap: false,
    previews: {},
    SpecificationArray: [],
    searchspec: "",

    menu: false,
    menu2: false,
    search: "",
    adddialog: false,
    editdialog: false,
    valid: false,
    snackbar: false,
    text: "",
    color: "",
    check: true,
    validForm: false,
    editProductForm: false,
    loading: true,

    dialog1: false,
    new_category: "",

    dialog2: false,
    new_sub_category: "",

    dialog3: false,

    TransitModelHeader: [
      { text: "ID", value: "id", sortable: false },
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "specification_id", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Brand", value: "product_brand", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "quantity", value: "quantity", sortable: false },
      { text: "Req Qty ", value: "actionsqty", sortable: false },
      //   { text: "Sell", value: "selling_price", sortable: false },
    ],

    products: [],
    contact: {},
    editedIndex: -1,
    allBrands: [],

    editedItem: {},

    user: {},

    singleExpand: false,

    resultURL: "",

    addquantitydialog: false,
    shop: [],
    warehouse: [],
    priceValidationForm: false,
    specdataobj: {
      product_data: {},
      product_price: {
        price: 0,
        purchase_price: 0,
      },
    },
    searchchalan: "",
    AllWarehouseList: [],
    trnasferob: {},
    selected: [],
    Transfersavedialog: false,
    singleSelect: false,
    valid_add_discount: false,
    addDiscountDialog: false,
    specdataobjdiscount: {
      product_data: {},
    },

    menudist: false,
    menu2dist: false,
    addedwarehousequantity: [],
    addedshopquantity: [],
    quantityIndex: -1,
    quantityID: -1,
    allchalanArray: [],
    chalanproducts: [],
    allChalanobj: {},
    viewchalandialog: false,
    tempobj: {
      data: {},
    },
    searchitemArray: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  methods: {
    initialize() {
      axios.get("manager/transferable_list").then((response) => {
        if (response.data.success) {
          this.AllWarehouseList = response.data.data;
          this.loading = false;
          this.AllWarehouseList.forEach((element, index) => {
            element.flagvalue = element.value;
            element.value = index;
          });
        }
      });
    },

    savetransit(selected) {
      // console.log("this is ", this.selected);
      this.Transfersavedialog = true;
    },
    CancelInvoice() {
      this.Transfersavedialog = false;
    },

    Canceldiscount() {
      this.addDiscountDialog = false;
    },

    TransferSelectInfo() {
      // console.log(this.trnasferob);
      axios
        .get(
          `hub/get_house_data/${this.trnasferob.id}/${this.trnasferob.flagvalue}/`
        )
        .then((response) => {
          if (response.data.success) {
            this.allchalanArray = response.data.data;
          }
        });
    },

    SaveApproveInvoice(trnasferob) {
        
        this.selected.forEach((element) => {
        element.requested_qty = parseInt(element.requested_qty);
        element.request_setter=parseInt(this.$route.query.id);
        element.setter_flag=parseInt(this.$route.query.flag);
        element.getter_flag =parseInt(this.trnasferob.value);
        element.request_getter = parseInt(this.trnasferob.id);    
        element.manager_id = this.user.user_id;    
      });

      let datas = {
        admin_id: this.user.user_id,
        requested_products: this.selected,
      };

  
      axios
        .post("hub/genarate_warehouse_transfer/", datas)
        .then((response) => {
          if (response.data.success) {
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            this.Transfersavedialog = false;
            // this.allchalanArray = this.allchalanArray.filter((element) => {
            // this.selected.some((item) => item.id == element.id);
            // });
            // this.selected = [];
            // this.TransferSelectInfo();
            // this.viewchalandialog = true;
            
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },
    viewchalanAction(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/admin_chalan/${item.id}`,
      });
      routeData.href = `/dashboard/pages/admin_chalan/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },
    viewChalan(tempobj) {
      // console.log("this is clicked", tempobj);
      this.$router.push({
        path: "/dashboard/pages/admin_chalan/",
        query: { id: this.tempobj.data.id },
      });
    },

    myItems(items) {
      // console.log("After sorted", items);
      this.searchitemArray = items;
    },
    printSpec(searchitemArray) {
      let href = axios.defaults.baseURL + "site/allspec_pdf";
      window.open(href, "_blank");
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.initialize();
    this.TransferSelectInfo();
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

