var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-tabs',{attrs:{"color":"deep-purple accent-4"}},[_c('v-tab',[_vm._v("Sent Request")]),_c('v-tab',[_vm._v("Recieved Request")]),_c('v-tab-item',{staticClass:"mt-10"},[_c('v-tabs',{attrs:{"color":"green"}},[_c('v-tab',[_vm._v("All")]),_c('v-tab',[_vm._v("Pending")]),_c('v-tab',[_vm._v("Approved")]),_c('v-tab',[_vm._v("Generate Transfer")]),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.sent_all,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5","font-size":"26px"},attrs:{"elevation":"2"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.Approve(item)}}},[_vm._v("Details")])]}},{key:"item.dateaction",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(new Date(item.date).toDateString())+" ")])]}},{key:"item.leng",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.transaction_ids.length)+" ")])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.sent_pending,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5","font-size":"26px"},attrs:{"elevation":"2"}},[_c('v-spacer')],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.Approve(item)}}},[_vm._v("Details")])]}},{key:"item.dateaction",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(new Date(item.date).toDateString())+" ")])]}},{key:"item.leng",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.transaction_ids.length)+" ")])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.sent_approved,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5","font-size":"26px"},attrs:{"elevation":"2"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.Approve(item)}}},[_vm._v("Details")])]}},{key:"item.dateaction",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(new Date(item.date).toDateString())+" ")])]}},{key:"item.leng",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.transaction_ids.length)+" ")])]}}])})],1),_c('v-tab-item',[_c('GenerateTransfer')],1)],1)],1),_c('v-tab-item',{staticClass:"mt-10"},[_c('v-tabs',{attrs:{"color":"green"}},[_c('v-tab',[_vm._v("All")]),_c('v-tab',[_vm._v("Pending")]),_c('v-tab',[_vm._v("Approved")]),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.received_all,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5","font-size":"26px"},attrs:{"elevation":"2"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.GetApprove(item)}}},[_vm._v("Details")])]}},{key:"item.dateaction",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(new Date(item.date).toDateString())+" ")])]}},{key:"item.leng",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.transaction_ids.length)+" ")])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.received_pending,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5","font-size":"26px"},attrs:{"elevation":"2"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.GetApprove(item)}}},[_vm._v("Details")])]}},{key:"item.dateaction",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(new Date(item.date).toDateString())+" ")])]}},{key:"item.leng",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.transaction_ids.length)+" ")])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.received_approved,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5","font-size":"26px"},attrs:{"elevation":"2"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.GetApprove(item)}}},[_vm._v("Details")])]}},{key:"item.dateaction",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(new Date(item.date).toDateString())+" ")])]}},{key:"item.leng",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.transaction_ids.length)+" ")])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }